<template>
  <v-container id="owner-detail" fluid tag="section">
    <v-row justify="center">
      <dialog-message
        :dialog_message="dialog_message"
        :message="message"
      ></dialog-message>
      <v-col cols="12" md="10">
        <base-material-card>
          <template v-slot:heading>
            <div
              class="font-weight-light text-center text-h3 font-weight-medium"
              v-if="!createOwner"
            >
              {{ urban.name }}
            </div>
            <div
              class="font-weight-light text-center text-h3 font-weight-medium"
              v-if="createOwner"
            >
              {{ $t("owner.createOwner") }}
            </div>
          </template>

          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-if="createOwner"
                    class="purple-input"
                    :label="$t('owner.urban_renewal')"
                    v-model="urban.name"
                    readonly
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('owner.owner_name')"
                    :rules="fieldRules"
                    v-model="item.owner_name"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12" md="6">
                  <v-text-field v-model="item.owner_name_auto" :label="$t('owner.owner_name_auto')"></v-text-field>
                </v-col> -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="item.owner_id"
                    :label="$t('owner.owner_id')"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12" md="6">
                  <v-text-field v-model="item.owner_id_auto" :label="$t('owner.owner_id_auto')"></v-text-field>
                </v-col> -->
                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('owner.regno_no')"
                    type="number"
                    :rules="fieldRules"
                    v-model="item.regno_no"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="item.phone1"
                    :label="$t('owner.phone1')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="item.phone2"
                    :label="$t('owner.phone2')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="item.contact_address"
                    :label="$t('owner.contact_address')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="item.owner_address"
                    :label="$t('owner.owner_address')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-radio-group :label="$t('owner.excludeCount') + '：'" row>
                    <v-checkbox
                      class="mx-3"
                      @click="getExcludeType()"
                      v-model="seize"
                      :label="$t('owner.seize')"
                    ></v-checkbox>
                    <v-checkbox
                      class="mx-3"
                      @click="getExcludeType()"
                      v-model="detention"
                      :label="$t('owner.detention')"
                    ></v-checkbox>
                    <v-checkbox
                      class="mx-3"
                      @click="getExcludeType()"
                      v-model="punish"
                      :label="$t('owner.punish')"
                    ></v-checkbox>
                    <v-checkbox
                      class="mx-3"
                      @click="getExcludeType()"
                      v-model="collapse"
                      :label="$t('owner.collapse')"
                    ></v-checkbox>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-card>
                    <v-row>
                      <v-col cols="12" md="2">
                        <v-select
                          class="mx-2"
                          :label="$t('owner.lbType')"
                          :items="lb_type"
                          v-model="lbType"
                          item-text="text"
                          item-value="value"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="10">
                      </v-col>
                      <v-col cols="12" md="4" v-if="lbType=='B'">
                        <v-select
                          class="mx-2"
                          :label="$t('urbanRenewal.city_name')+'/'+$t('urbanRenewal.area_name')+'/'+$t('urbanRenewal.region_name')"
                          :items="region_info"
                          v-model="region_code"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="8" v-if="lbType=='B'">
                        <v-row>
                          <v-col cols="6" class="py-0 ml-2">
                            <v-text-field
                              :label="$t('owner.buildingMajorNumber')"
                              v-model="buildingMajorNumber" />
                          </v-col>
                          <v-col cols="5" class="py-0">
                            <v-text-field
                              prepend-icon="mdi-minus"
                              :label="$t('owner.buildingMinorNumber')"
                              v-model="buildingMinorNumber" />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="4" v-else>
                        <v-select
                          class="mx-2"
                          :label="$t('land.land_no')"
                          :items="land_infos"
                          v-model="select_land_no"
                          :item-text="getLandName"
                          item-value="id"
                          @change="setLandArea"
                        ></v-select>
                      </v-col>
                      <template v-if="lbType=='B'">
                        <v-col cols="12" md="6">
                          <v-select
                            class="mx-2"
                            :label="$t('building.relation')"
                            :items="land_infos"
                            v-model="select_land_relation"
                            :item-text="getLandName"
                            item-value="id"
                            multiple
                            @change="setRealtionArea"
                          >
                            <template v-slot:selection="{ item, index }"><div v-if="index==0" class="relation">{{ getLandName(item) }} <span v-if="select_land_relation.length!=1">等{{ select_land_relation.length }}筆地號</span></div></template>
                          </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            class="mx-2"
                            v-model="relation_area"
                            type='number'
                            readonly
                            :label="$t('land.land_area')"
                          ></v-text-field>
                        </v-col>
                      </template>
                      <v-col cols="12" md="4">
                        <v-text-field
                          class="mx-2"
                          v-model="area"
                          type='number'
                          :readonly="lbType=='L'"
                          :label="lb_area"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" :md="lbType=='L'?2:4">
                        <v-text-field
                          class="mx-2"
                          v-model="numerator"
                          type='number'
                          :label="$t('owner.numerator')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" :md="lbType=='L'?2:4">
                        <v-text-field
                          class="mx-2"
                          v-model="denominator"
                          type='number'
                          :label="$t('owner.denominator')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="lbType=='B'">
                        <v-text-field
                          class="mx-2"
                          v-model="building_address"
                          :label="$t('building.building_address')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="text-right">
                        <v-btn color="primary" dark class="mx-2" v-if="lbType=='B'" @click="addBuildingRelation()">
                          <v-icon left>mdi-pencil-plus</v-icon>
                          {{ $t("owner.createBuildingInfo") }}
                        </v-btn>
                        <v-btn color="primary" dark class="mx-2" v-if="lbType=='L'" @click="addLandRelation()">
                          <v-icon left>mdi-pencil-plus</v-icon>
                          {{ $t("owner.createLandInfo") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-data-table
                    :headers="building_headers"
                    :items="item.building_info"
                    :loading="building_loading"
                  >
                  <template v-slot:item="{ item, expand, isExpanded }">
                    <tr v-if="!item.is_removed">
                      <td @click="expand(!isExpanded)">
                        <v-flex>
                          <span>{{ item.building_name }}</span>
                        </v-flex>
                      </td>
                      <td>
                        <v-flex>
                          <span>{{ item.relation.name }}</span>
                        </v-flex>
                      </td>
                      <td>
                        <v-flex>
                          <span>{{ areaFormat(item.building_area) }}</span>
                        </v-flex>
                      </td>
                      <td>
                        <v-flex>
                          <span>{{ item.building_address }}</span>
                        </v-flex>
                      </td>
                      <td>
                        <v-flex>
                          <span :style="ifInvalid(item.building_numerator)">{{ item.building_numerator }}/{{ item.building_denominator }}</span>
                        </v-flex>
                      </td>
                      <td>
                        <v-flex>
                          <v-dialog
                            max-width="800px"
                            v-model="itemDialog"
                          >
                             <template v-slot:activator="{ on }">
                              <v-btn 
                                color="primary" 
                                dark 
                                class="mx-2" 
                                small 
                                v-on="on"
                                @click="openDialog(item)"
                              >
                                {{ $t("common.edit") }}
                              </v-btn>
                            </template>
                            <v-card>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-select
                                      class="mx-2"
                                      :label="$t('building.relation')"
                                      :items="land_infos"
                                      v-model="currentItem.land_relation"
                                      :item-text="getLandName"
                                      item-value="id"
                                      multiple
                                      @change="setRealtionArea"
                                    >
                                      <template v-slot:selection="{ item, index }"><div v-if="index==0" class="relation">{{ getLandName(item) }} <span v-if="currentItem.land_relation.length!=1">等{{ currentItem.land_relation.length }}筆地號</span></div></template>
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-text-field
                                      class="mx-2"
                                      v-model="relation_area"
                                      type='number'
                                      readonly
                                      :label="$t('land.land_area')"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="4">
                                    <v-text-field
                                      class="mx-2"
                                      v-model="currentItem.building_area"
                                      type='number'
                                      :label="$t('building.building_area')"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="4">
                                    <v-text-field
                                      class="mx-2"
                                      v-model="currentItem.building_numerator"
                                      type='number'
                                      :label="$t('owner.numerator')"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="4">
                                    <v-text-field
                                      class="mx-2"
                                      v-model="currentItem.building_denominator"
                                      type='number'
                                      :label="$t('owner.denominator')"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-text-field
                                      class="mx-2"
                                      v-model="currentItem.building_address"
                                      :label="$t('building.building_address')"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" class="text-right">
                                    <v-btn
                                      color="success"
                                      class="mr-0"
                                      @click="saveItem()"
                                      >{{ $t("common.confirm") }}{{ $t("common.edit") }}</v-btn
                                    >
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-dialog>
                          <v-btn
                            color="red"
                            class="mx-2"
                            small
                            @click="RemoveInfo(item, 'B')"
                            >{{ $t("common.delete") }}</v-btn
                          >
                        </v-flex>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:expanded-item="{ item }">
                    <td v-if="item.common_part.length!=0" :colspan="building_headers.length-1">
                      <v-data-table
                        :headers="common_part_header"
                        :items="item.common_part"
                        :loading="common_part_loading"
                        hide-default-footer
                      >
                        <template v-slot:item.building_no="{ item }">
                          <v-flex>
                            <span>
                              {{ item.region.city.name + item.region.district.name + item.region.name + item.building_no.split("_").pop() }}
                            </span>
                          </v-flex>
                        </template>
                        <template v-slot:item.building_area="{ item }">
                          <v-flex>
                            {{ areaFormat(item.building_area) }}
                          </v-flex>
                        </template>
                        <template v-slot:item.building_numerator="{ item }">
                          <v-flex>
                            <span>
                              {{ item.building_numerator}}/{{ item.building_denominator }}
                            </span>
                          </v-flex>
                        </template>
                        <template v-slot:item.building_denominator="{ item }">
                          <v-flex>
                            <span>
                              {{ (item.building_area * item.building_numerator / item.building_denominator).toFixed(2) }}
                            </span>
                          </v-flex>
                        </template>
                        <template slot="no-data">
                          <div>
                            {{ $t('common.noData') }}
                          </div>
                        </template>
                      </v-data-table>
                    </td>
                  </template>
                    <template slot="no-data">
                      <div>
                        {{ $t('common.noData') }}
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="12">
                  <v-data-table
                    :headers="land_headers"
                    :items="item.land_info"
                    :loading="land_loading"
                  >
                  <template v-slot:item="{ item }">
                    <tr v-if="!item.is_removed">
                      <td>
                        <v-flex>
                          <span>{{ item.land_no }}</span>
                        </v-flex>
                      </td>
                      <td>
                        <v-flex>
                          <span>{{ areaFormat(item.land_area) }}</span>
                        </v-flex>
                      </td>
                      <td>
                        <v-flex>
                          <span :style="ifInvalid(item.land_numerator)">{{ item.land_numerator }}/{{ item.land_denominator }}</span>
                        </v-flex>
                      </td>
                      <td>
                        <v-flex>
                          <v-btn
                            color="red"
                            class="mx-2"
                            small
                            @click="RemoveInfo(item, 'L')"
                            >{{ $t("common.delete") }}</v-btn
                          >
                        </v-flex>
                      </td>
                    </tr>
                  </template>
                    <template slot="no-data">
                      <div>
                        {{ $t('common.noData') }}
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="item.memo"
                    :label="$t('common.memo')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn color="aqua" class="mx-2" @click="back(urban)">{{
                    $t("common.back")
                  }}</v-btn>
                  <v-btn
                    v-if="!createOwner"
                    color="success"
                    class="mr-0"
                    @click="patchApi()"
                    >{{ $t("common.save") }}</v-btn
                  >
                  <v-btn
                    v-if="createOwner"
                    color="success"
                    class="mr-0"
                    :disabled="loading"
                    @click="save()"
                    >{{ $t("common.save") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import CommonUtil from "@/utils/commonutil.js";
export default {
  data: (vm) => ({
    valid: true,
    dialog_message: false,
    createOwner: false,
    seize: false,
    detention: false,
    punish: false,
    collapse: false,
    lbType: 'L',
    urban: { name: null },
    lbTypeNo: vm.$i18n.t("land.land_no"),
    lb_area: vm.$i18n.t("land.land_area"),
    item: {
      owner_name: "",
      owner_name_auto: null,
      owner_id: "",
      owner_id_auto: null,
      regno_no: null,
      phone1: "",
      phone2: "",
      contact_address: "",
      owner_address: "",
      exclude_type: 0,
      memo: null,
      building_info: [],
      land_info: [],
    },
    building_loading: false,
    land_loading: false,
    common_part_loading: false,
    building_headers: [
      {
        sortable: false,
        text: vm.$i18n.t("building.building_no"),
        value: "building_name",
      },
      {
        sortable: false,
        text: vm.$i18n.t("building.relation"),
        value: "relation",
      },
      {
        sortable: false,
        text: vm.$i18n.t("building.building_area"),
        value: "building_area",
      },
      {
        sortable: false,
        text: vm.$i18n.t("building.building_address"),
        value: "building_address",
      },
      {
        sortable: false,
        text: vm.$i18n.t("owner.own_rate"),
        value: "building_numerator",
      },
      {
        sortable: false,
        // text: vm.$i18n.t("common.actions"),
        text: '',
        value: "actions",
      },
    ],
    land_headers: [
      {
        sortable: false,
        text: vm.$i18n.t("land.land_no"),
        value: "land_no",
      },
      {
        sortable: false,
        text: vm.$i18n.t("land.land_area"),
        value: "land_area",
      },
      {
        sortable: false,
        text: vm.$i18n.t("owner.own_rate"),
        value: "land_numerator",
      },
      {
        sortable: false,
        // text: vm.$i18n.t("common.actions"),
        text: '',
        value: "actions",
      },
    ],
    common_part_header: [
      {
        sortable: false,
        text: vm.$i18n.t("common_part.building_no"),
        value: "building_no",
      },
      {
        sortable: false,
        text: vm.$i18n.t("common_part.building_area"),
        value: "building_area",
      },
      ,
      {
        sortable: false,
        text: vm.$i18n.t("common_part.building_rate"),
        value: "building_numerator",
      },
      ,
      {
        sortable: false,
        text: vm.$i18n.t("common_part.building_rate_area"),
        value: "building_denominator",
      },
    ],
    message: {
      title: "",
      text: "",
    },
    excludeType: {
      seize: 1,
      detention: 2,
      punish: 4,
      collapse: 8,
    },
    lb_type: [
      {
        text: "土地",
        value: "L"
      },
      {
        text: "建物",
        value: "B"
      },
    ],
    fieldRules: [(v) => !!v || vm.$i18n.t("common.missing")],
    city_info: [],
    district_info: [],
    region_info: [],
    district_list: [],
    region_list: [],
    city_code: null,
    district_code: null,
    region_code: null,
    area: null,
    building_no: null,
    building_address: '',
    numerator: null,
    denominator: null,
    select_land_no: null,
    select_land_relation: [],
    relation_area: null,
    buildingMajorNumber: null,
    buildingMinorNumber: null,
    building_infos: [],
    land_infos: [],
    common_part: [],
    itemDialog: false,
    currentItem: {
      land_relation: [],
      building_area: null,
      building_numerator: null,
      building_denominator: null,
      building_address: null,
    }
  }),
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    ...mapMutations(["setTitle"]),
    getApi() {
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/owner/" + this.item + "/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    getUrbanLandNo(){
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/urban/" + this.urban + "/landNo/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          currentObj.item.regno_no = response.data.owner_max_no +1;
          currentObj.urban = response.data.urban_renewal;
          currentObj.setInfos(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      // if (this.item.contact_address == "" && this.item.owner_address == "") {
      //   this.message.title = this.$t("save-fail");
      //   this.message.text = "地址未填寫";
      //   this.dialog_message = !this.dialog_message;
      //   return;
      // }
      // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/owner/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };
      let data = {
        urban_renewal_id: this.urban.id,
        owner_name: this.item.owner_name,
        owner_name_auto: this.item.owner_name_auto,
        owner_id: this.item.owner_id,
        owner_id_auto: this.item.owner_id_auto,
        regno_no: this.item.regno_no,
        phone1: this.item.phone1,
        phone2: this.item.phone2,
        contact_address: this.item.contact_address,
        owner_address: this.item.owner_address,
        exclude_type: this.item.exclude_type,
        memo: this.item.memo,
        building_info: this.item.building_info,
        land_info: this.item.land_info,
      };
      this.axios
        .post(url, data, config)
        .then(function (response) {
          currentObj.loading = false;
          alert("送出成功");
          currentObj.back(currentObj.urban);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("save-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    patchApi() {
      if (!this.$refs.form.validate()) {
        return;
      }
      // if (this.item.contact_address == "" && this.item.owner_address == "") {
      //   this.message.title = this.$t("save-fail");
      //   this.message.text = "地址未填寫";
      //   this.dialog_message = !this.dialog_message;
      //   return;
      // }
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/owner/" + this.item.id + "/";

      const jsonData = {
        urban_renewal_id: this.urban.id,
        owner_name: this.item.owner_name,
        owner_name_auto: this.item.owner_name_auto,
        owner_id: this.item.owner_id,
        owner_id_auto: this.item.owner_id_auto,
        regno_no: this.item.regno_no,
        phone1: this.item.phone1,
        phone2: this.item.phone2,
        contact_address: this.item.contact_address,
        owner_address: this.item.owner_address,
        exclude_type: this.item.exclude_type,
        memo: this.item.memo,
        building_info: this.item.building_info,
        land_info: this.item.land_info,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };
      this.axios
        .patch(url, jsonData, config)
        .then(function (response) {
          alert("更新成功。");
          currentObj.back(currentObj.urban);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("update-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
      this.dialog_form = false;
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.urban = info.owner.urban_renewal;
      this.totalItems = info.length;
      data.owner.building_info = [];
      data.owner.land_info = [];
      this.item = data.owner;
      if (!data.owner.owner_name){
        this.item.owner_name = data.owner.owner_name_auto
      }
      if (!data.owner.owner_id){
        this.item.owner_id = data.owner.owner_id_auto
      }
      this.common_part = data.commonpart_info
      this.setRelations(data)
      this.setInfos(data)
      this.setCommonPart(data)
      this.checkExcludeType();
    },
    setInfos(data){
      this.building_infos = data.building_info
      this.land_infos = data.land_info
      this.setCDRlist()
    },
    setCDRlist(){
      this.city_info = []
      this.district_list = []
      this.region_list = []
      this.land_infos.forEach(element=>{
        // let city_code = element.region.city.code
        // let city_item = {
        //   'text': element.region.city.name,
        //   'value': city_code
        // }
        // this.city_info.push(city_item)
        // this.district_list.push(element.region.district)
        // this.region_list.push(element.region)
        let city_code = element.region.city.code
        let district_code = element.region.district.code
        let region_item = {
          'text': element.region.city.name + element.region.district.name + element.region.name,
          'value': city_code + "_" + district_code + "_" + element.region.code,
          'item': element.region.id
        }
        this.region_info.push(region_item)
      })
    },
    setRelations(data){
      this.item.building_info = []
      this.item.land_info = []

      data.land_relation.forEach(element=>{
        let exist_land_relation = {
          is_removed: false,
          region_id: element.land_info.region.id,
          land_denominator: element.land_denominator,
          land_numerator: element.land_numerator,
          land_info_id: element.land_info.id,
          id: element.id,
          land_area: parseFloat(element.land_info.land_area).toFixed(4),
          land_no: element.land_info.region.city.name + element.land_info.region.district.name + element.land_info.region.name + element.land_info.land_no.split('_').pop()
        }
        this.item.land_info.push(exist_land_relation)
      })

      data.building_relation.forEach(element=>{
        let relation_name = ""
        let relation_name_list = []
        if(element.building_info.land_relations != null){
          let land_related_first = element.building_info.land_relations.split(",")
          let land_info = data.land_info.filter(element=>
            // element.id.toString() == land_related_first
             land_related_first.indexOf(element.id.toString()) != -1
          )
          if(land_info.length != 0){
            for(let i=0;i<land_info.length;i++){
              let land_name = land_info[i].region.city.name + land_info[i].region.district.name + land_info[i].region.name + land_info[i].land_no.split('_').pop()
              relation_name_list.push(land_name)
            }
            relation_name = relation_name_list.toString()
          }
        }
        let relation_data = {
          name: relation_name,
          ids: element.building_info.land_relations
        }
        console.log(relation_data)
        let exist_building_relation = {
          is_removed: false,
          region_id: element.building_info.region.id,
          building_address: element.building_info.building_address,
          building_denominator: element.building_denominator,
          building_numerator: element.building_numerator,
          building_info_id: element.building_info.id,
          id: element.id,
          building_area: parseFloat(element.building_info.building_area).toFixed(4),
          building_no: element.building_info.building_no,
          building_name: element.building_info.region.city.name + element.building_info.region.district.name + element.building_info.region.name + element.building_info.building_no.split("_").pop(),
          relation: relation_data
        }
        this.item.building_info.push(exist_building_relation)
      })
    },
    setCommonPart(data){
      this.item.building_info.forEach(element=>{
        element.common_part = []
        this.common_part.forEach(element1=>{
          if(element1.building_info.id == element.building_info_id){
            element.common_part.push(element1)
          }
        })
      })
    },
    setLandArea(item){
      let select_land = this.land_infos.find(element=>{
        return element.id == item
      })
      this.area = select_land.land_area
    },
    setRealtionArea(items){
      let relation_area = 0;
      this.land_infos.forEach(land_info=>{
        if(items.indexOf(land_info.id)!= -1){
          relation_area += parseFloat(land_info.land_area);
        }
      })
      if(relation_area == 0){
        this.relation_area = null;
      } else {
        this.relation_area = relation_area.toFixed(2);
      }
    },
    back(urban) {
      this.$router.push({
        name: "OwnerList",
        query: {
          item: urban.id,
          urban_name: urban.name
        },
      });
    },
    getExcludeType() {
      this.item.exclude_type = 0;
      if (this.seize == true) {
        this.item.exclude_type += this.excludeType.seize;
      }
      if (this.detention == true) {
        this.item.exclude_type += this.excludeType.detention;
      }
      if (this.punish == true) {
        this.item.exclude_type += this.excludeType.punish;
      }
      if (this.collapse == true) {
        this.item.exclude_type += this.excludeType.collapse;
      }
    },
    checkExcludeType() {
      if (this.item.exclude_type & this.excludeType.seize) {
        this.seize = true;
      }
      if (this.item.exclude_type & this.excludeType.detention) {
        this.detention = true;
      }
      if (this.item.exclude_type & this.excludeType.punish) {
        this.punish = true;
      }
      if (this.item.exclude_type & this.excludeType.collapse) {
        this.collapse = true;
      }
    },
    getDistrictsApi() {
      this.district_info = [];
      this.region_info = [];
      this.district_list.forEach(element=>{
        if(element.city.code == this.city_code){
          this.district_info.push(element)
        }
      })
    },
    getRegionApi() {
      this.region_info = [];
      this.region_list.forEach(element=>{
        if(element.city.code == this.city_code && element.district.code == this.district_code){
          this.region_info.push(element)
        }
      })
    },
    getRegionDisplay(item) {
      return item.name;
    },
    getLandName(item){
      return item.region.city.name + item.region.district.name + item.region.name + item.land_no.split("_").pop()
    },
    addBuildingRelation(){
      // let new_building_no = this.city_code+"_"+this.district_code+"_"+this.region_code+"_"+this.buildingMajorNumber+"-"+this.buildingMinorNumber
      let new_building_no = this.region_code+"_"+this.buildingMajorNumber+"-"+this.buildingMinorNumber
      let building_info = this.building_infos.find(element=>{
        return element.building_no == new_building_no
      })
      let item_index = this.item.building_info.findIndex(element=>{
        return element.building_no == new_building_no && element.is_removed == false
      })
      if(item_index != -1){
        this.message.title = "錯誤提示";
        this.message.text = "已存在的建號";
        this.dialog_message = !this.dialog_message;
        return;
      }
      let selected_building_no  = null;
      if(building_info){
        selected_building_no = building_info.id
      }
      let building_region = this.region_info.find(element=>{
        return element.value == this.region_code
      })
      let relation = this.getRelations(this.select_land_relation)
      let new_relation = {
        is_removed: false,
        region_id: building_region.item,
        id: null,
        building_address: this.building_address,
        building_denominator: this.denominator,
        building_numerator: this.numerator,
        building_info_id: selected_building_no,
        building_area: parseFloat(this.area).toFixed(4),
        building_no: new_building_no,
        building_name: building_region.text + new_building_no.split("_").pop(),
        relation: relation
      }
      
      this.item.building_info.push(new_relation)
    },
    addLandRelation(){
      let land_info = this.land_infos.find(element=>{
        return element.id == this.select_land_no
      })
      let item_index = this.item.land_info.findIndex(element=>{
        return element.land_info_id == this.select_land_no && element.is_removed == false
      })
      if(item_index != -1){
        this.message.title = "錯誤提示";
        this.message.text = "已存在的地號";
        this.dialog_message = !this.dialog_message;
        return;
      }
      let new_relation = {
        id: null,
        is_removed: false,
        land_denominator: this.denominator,
        land_numerator: this.numerator,
        land_info_id: this.select_land_no,
        land_area: land_info.land_area,
        land_no: land_info.region.city.name + land_info.region.district.name + land_info.region.name + land_info.land_no.split("_").pop()
      }
      this.item.land_info.push(new_relation)
    },
    RemoveInfo(item, lbtype){
      if(!item.id){
        let infos = null
        if(lbtype == 'B'){
          infos = this.item.building_info
        } else {
          infos = this.item.land_info
        }
        let item_index = infos.findIndex(element=>{
          return element == item
        })
        infos.splice(item_index,1)
      } else{
        item.is_removed = true
      }
    },
    getRelations(index_arr){
      let name_list = [];
      let id_arr = [];
      index_arr.forEach((land_index, index)=>{
        let land_info = this.land_infos.find(element=>{
          return element.id == land_index
        })
        name_list.push(this.getLandName(land_info))
        id_arr.push(land_info.id)
      })
      let relation = {
        name: name_list.toString(),
        ids: id_arr.toString()
      }
      return relation
    },
    openDialog(item){
      this.currentItem = Object.assign({}, item);
      this.currentItem["land_relation"] = item.relation.ids.split(',').map(x=>+x)
      this.setRealtionArea(this.currentItem["land_relation"])
    },
    saveItem(){
      this.currentItem["relation"] = this.getRelations(this.currentItem["land_relation"])
      let change_item = this.item.building_info.find((element)=>{return element.building_no == this.currentItem.building_no})
      Object.assign(change_item, this.currentItem)
      this.itemDialog = !this.itemDialog
    },
    areaFormat(area){
      return CommonUtil.areaFormat(area)
    },
    ifInvalid(target){
      if(target==0){
        return { color: 'red', fontWeight: 600 }
      } else {
        return {}
      }
    }
  },
  watch:{
    lbType(){
      if(this.lbType == "B"){
        this.lbTypeNo = this.$t('building.building_no')
        this.lb_area = this.$t('building.building_area')
        this.select_land_no = null
        this.area = null
        this.denominator = null
        this.numerator = null
      } else {
        this.lbTypeNo = this.$t('land.land_no')
        this.lb_area = this.$t('land.land_area')
        this.select_land_no = null
        this.area = null
        this.city_code = null
        this.district_code = null
        this.region_code = null
        this.denominator = null
        this.numerator = null
      }
    }
  },
  mounted() {
    this.setTitle(this.$i18n.t("title.OwnerDetail"));
    this.urban = this.$route.query.urban;
    if (this.$route.query.item) {
      this.item = this.$route.query.item;
      this.getApi();
    } else {
      this.createOwner = true;
      this.getUrbanLandNo();
    }
  },
};
</script>

<style lang="sass" scoped>
.relation
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
</style>